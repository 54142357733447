import axios from 'axios'
// import router from '@/router'
// 配置请求头
const headerPZ = {
  'vchannel': 'pc',
  'version' : '1.1.0',
  'groupcode': '7220',
}

const pending = []
const removePending = (config) => {
  for (const i in pending) {
    if (pending[i].u === config.url + '&' + config.method) { // 当当前请求在数组中存在时执行函数体
      // console.log('cancel:', config.url)
      pending[i].f() // 对该次接口之前发送的的同名接口执行取消操作
      pending.splice(i, 1) // 把这条记录从数组中移除
    }
  }
}
// create an axios instance
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  // baseURL: 'http://10.31.4.245:7788', // api的base_url
  baseURL: 'api', // api的base_url
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
  removePending(config)
  config.headers.vchannel = headerPZ.vchannel;
  config.headers.version = headerPZ.version;
  config.headers.groupcode = headerPZ.groupcode;
  config.headers.membercode = localStorage.getItem('membercode') || '';
  config.headers.token = localStorage.getItem('token') || '';

  return config;
}, error => {
  console.log(error)
})




// respone interceptor
service.interceptors.response.use(response => {
  const res = response.data
  return res
}, error => {
  return Promise.reject(error)
})

export default async function request(config) {
  try {
    return await service(config)
  } catch (err) { // 出错重新发送
    if (!err.message) return Promise.reject(err)// 请求被取消时，无message，此时不处理
    if (config.retryCount) {
      return request({
        ...config,
        timeout: config.timeout + (config.retryDiffTime || 1000),
        retryCount: config.retryCount - 1
      })
    } else {
      // console.log(config.url, err)
      return Promise.reject(err)
    }
  }
}
