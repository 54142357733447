// import { api_getPhoneQRcode } from '@/api/order'
import { api_projectTypeList, api_appInfo } from '@/api/home'

const config = {
  state: {
    ifUserLogin: false,
    groupcode: '7220',
    userInfo: {},
    phoneQRcodeImg: '',
    typeList: [],
    clearSearchFlag: false,
    path:'https://api.jssypw.com/',
    platformSettings: []
  },
  mutations: {
    SET_USER_LOGIN(state, flag) {
      state.ifUserLogin = flag
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data
    },
    SET_PHONE_QRCODE_IMG(state, data) {
      state.phoneQRcodeImg = data
    },
    SET_TYPE_LIST(state, data) {
      state.typeList = data
    },
    SET_CLEAR_SEARCH_FLAG(state, data) {
      state.clearSearchFlag = data
    },
    SET_PLATFORM_SETTINGS(state, data) {
      state.platformSettings = data
      state.phoneQRcodeImg = data.length && data.find(el => el.name == 'XCXorH5qrcode').value
    },
  },
  actions: {
    HandleLogin({commit}) {
      commit('SET_USER_LOGIN', true)
    },
    HandleLogout({commit}) {
      commit('SET_USER_LOGIN', false)
    },
    GetIfLogin({commit}) {
      commit('SET_USER_LOGIN', localStorage.getItem('membercode'))
      commit('SET_USER_INFO', localStorage.getItem('userInfo'))
    },
    GetphoneQRcodeImg({commit}) {
      const str = config.state.path+"/static/"+config.state.groupcode + ".png";
      commit('SET_PHONE_QRCODE_IMG', str)
    },
    GetType({commit}) {
      const params = {
        groupcode: config.state.groupcode
      }
      api_projectTypeList(params).then(res => {
        if (res.code == '0') {
          commit('SET_TYPE_LIST', res.data)
        }
      })
    },
    HandleClearSearch({commit}, flag) {
      commit('SET_CLEAR_SEARCH_FLAG', flag)
    },
    GetSetting({commit}) {
      api_appInfo().then(res => {
        if (res.code == '0') {
          commit('SET_PLATFORM_SETTINGS', res.data.platformSettings)
        }
      })
    },
  }
}

export default config