<template>
  <div class="header header-wrap">
    <div class="header-box dfacjsb">
      <div class="df">
        <div @click="handleNavigateHome" class="img-box pointer">
          <img class="logo" :src="logoImg" alt="">
        </div>
        <!-- 导航tab -->
        <ul class="header-tab">
          <li
            class="selectTabLi"
            :class="{'li-active': liCurrentIndex === 0 || liClickCurrentIndex === 0}"
            @mouseenter="handleMouseEnter('', '', 0)"
            @mouseleave="handleMouseLeave()"
            @click="handleClick(0, 'MyHome')">首页</li>
          <li
            class=""
            :class="{'li-active': liCurrentIndex === 1 || liClickCurrentIndex === 1}"
            id="showAllType"
            @mouseenter="handleMouseEnter('all', '', 1)"
            @mouseleave="handleMouseLeave('all')"
            @click.stop="handleClick(1, 'AllShow')">
            全部演出
            <div v-if="allShow" class="abs w100">
              <div
                v-for="(item1, index) in showList"
                :key="item1.name"
                class="w100 show-list"
                :class="{'item-active': itemCurrentIndex===index}"
                @mouseenter="handleMouseEnter('item', index)"
                @click.stop="handleNavigateShow(item1)"
                >
                <div class="w100 dfac">
                  <img :src="item1.typeicon" alt="">
                  <span class="fs15">{{item1.typename}}</span>
                </div>  
              </div>
            </div>
          </li>
          <li
            class="h5-li"
            :class="{'li-active': liCurrentIndex === 2 || liClickCurrentIndex === 2}"
            id="phone"
            @mouseenter="handleMouseEnter('h5', '', 2)"
            @mouseleave="handleMouseLeave('h5')"
            @click="handleClick(2)">
            移动端
            <div class="qrcode-box" v-if="h5Show">
              <img
                :src="qrcodeImg"
                class="qrCode"
                />
              <p class="fs14 tc">微信扫描二维码</p>
            </div>
          </li>
        </ul> 
      </div>

      <div class="right-header dfac">
        <!-- 搜索框 -->
        <div class="search-header">        
          <div class="search-header-top df">
            <img src="@/assets/img/search-icon.png">
            <div class="line"></div>
            <input
              v-model="searchValue"
              @input="handleInputDebounce"
              @blur="handleBlur"
              @focus="handleFocus"
              @keydown.enter="handleNavigate('AllShow')"
              class="input-search"
              placeholder="输入演出名称">
            <div class="circle pointer" @click="handleNavigate('AllShow')">搜索</div>
          </div>
          <div class="search-header-bottom" v-if="searchResultShow&&searchList.length">
            <div
              class="dfacjsb"
              :class="{'serch-active':searchCurrentIndex===index}"
              v-for="(item, index) in searchList"
              :key="item.procode"
              @click="handleNavigate('ShowDetail', item)"
              @mouseenter="handleMouseEnter('search', index)"
              >
              <span style="display:inline-block;width:220px" class="ellipsis">{{item.proname}}</span>
              <span>{{item.cityname}}</span>
            </div>
          </div>
          <div class="search-header-bottom" v-if="searchResultShow&&!searchList.length&&showFlag">
            <div>
              暂无搜索结果
            </div>
          </div>
        </div>
        <!-- 个人中心 -->
        <div class="info-box user-header" v-if="ifLogin">
          <div
            class="login-user user-box dfac"
            @mouseenter="handleMouseEnter('user')"
            @mouseleave="handleMouseLeave('user')">
            <div>
              <span>{{mobile}}</span>
              <img src="@/assets/img/comSelectTriangle.png">
            </div>
            <div
              class="list-wrap hideDiv"
              v-if="userShow"
              >
              <div
                class="li-login"
                :class="{'user-active': userItemCurrentIndex===index}"
                v-for="(item, index) in userList"
                :key="item.name"
                @mouseenter="handleMouseEnter('userItem', index)"
                @click="handleClickUseItem(item)">{{item.text}}</div>
            </div>
          </div>
        </div>
        <div
          class="info-box user-header dfac"
          v-else
          @click="handleLogin">
          <img class="i-info-box i-user" src="@/assets/img/unlogin-icon.png">
          <div class="login-user">
            <span>登录</span>
          </div>
          <div class="login-user">
            <span>|</span>
          </div>
          <div class="login-user">
            <span>注册</span>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api_projectPage } from '@/api/allShow';
import { api_logout } from '@/api/login';
import { debounce } from '@/utils/tools'

export default {
  name: 'MyHeader',
  props: {
    msg: String
  },
  computed: {
    ifLogin() {
      console.log(this.$store.state.user.ifUserLogin)
      return this.$store.state.user.ifUserLogin
    },
    mobile() {
      return this.ifLogin ? JSON.parse(localStorage.getItem('userInfo')).mobile : ''
    },
    qrcodeImg() {
      return this.$store.state.user.phoneQRcodeImg
      // return this.$store.state.user.platformSettings.length && this.$store.state.user.platformSettings.find(el => el.name == 'XCXorH5qrcode').value
    },
    showList() {
      return this.$store.state.user.typeList
    },
    ifSearchClearFlag() {
      return this.$store.state.user.clearSearchFlag
    },
    logoImg() {
      return this.$store.state.user.platformSettings.length && this.$store.state.user.platformSettings.find(el => el.name == 'XCXorH5homepageLogoURL').value
    }
  },
  watch: {
    ifSearchClearFlag: {
      handler(newval) {
        if(newval){
          this.searchValue = ''
          this.$store.dispatch('HandleClearSearch', false)
        }
      },
    }
  },
  data() {
    return {
      allShow: false,
      h5Show: false,
      userShow: false,
      allCurrentIndex: -1,
      itemCurrentIndex: -1,
      h5CurrentIndex: -1,
      searchCurrentIndex: -1,
      userItemCurrentIndex: -1,
      liCurrentIndex: 0,
      liClickCurrentIndex: -1,
      searchValue: '',
      searchResultShow: false,
      searchList: [],
      userList: [
        {
          name: 'reward',
          text: '会员中心',
          pathUrl: 'rewardCenter'
        },
        {
          name: 'order',
          text: '我的订单',
          pathUrl: 'orderList'
        },
        {
          name: 'address',
          text: '收货地址',
          pathUrl: 'addressList'
        },
        {
          name: 'realname',
          text: '实名信息',
          pathUrl: 'realName'
        },
        {
          name: 'membercard',
          text: '会员卡',
          pathUrl: 'memberCard'
        },
        {
          name: 'coupon',
          text: '优惠券',
          pathUrl: 'couponList'
        },
        {
          name: 'out',
          text: '退出登录'
        }
      ],
      showFlag: false
    }
  },
  mounted() {
    console.log(this.$store.state, 'store')
    this.handleDebounce = debounce(() => {
      this.handleInput()
    }, 300)

    const pathName = this.$route.name
    if (pathName === 'AllShow') {
      this.liClickCurrentIndex = 1
      this.liCurrentIndex = 1
    } else if (pathName === 'MyHome') {
      this.liClickCurrentIndex = 0
      this.liCurrentIndex = 0
    } else {
      this.liClickCurrentIndex = -1
      this.liCurrentIndex = -1
    }
  },
  methods: {
    handleInputDebounce() {
      this.handleDebounce()
    },
    getSearchResultShow(params) {
      this.searchList = []
      this.showFlag = false
      api_projectPage(params).then(res => {
        if(res.code == '0') {
          this.searchList = res.data.records.length > 5 ? res.data.records.slice(0, 5) : res.data.records
          this.showFlag = true
        } else {
          this.searchList = []
        }
      })
    },
    handleMouseEnter(type, index, liIndex) {
      if (type) {
        this[type+'Show'] = true
      }
      if (index >= 0) {
        this[type+'CurrentIndex'] = index
      }
      if(liIndex >= 0) {
        this.liCurrentIndex = liIndex
      }
      this.$store.dispatch('HandleClearSearch', false)
    },
    handleMouseLeave(type) {
      this[type+'Show'] = false
      this[type+'CurrentIndex'] = -1
      this.liCurrentIndex = -1
    },
    handleClick(liIndex, routerName) {
      this.liClickCurrentIndex = -1
      this.liClickCurrentIndex = liIndex
      if (routerName) {
        this.$router.push({
          name: routerName
        })
        this.$store.dispatch('HandleClearSearch', true)
      }
    },
    handleInput() {
      this.searchResultShow = this.searchValue
      if (this.searchValue) {
        const params = {
          groupcode: this.$store.state.user.groupcode,
          proname: this.searchValue,
          page: 1,
          size: 20,
          sort: 1,
          typename: '',
          prodatetime: '',
          protype: '',
          theatercode: ''
        }
        this.getSearchResultShow(params)
        this.$store.dispatch('HandleClearSearch', false)
      }
    },
    handleBlur() {
      setTimeout(() => {
        this.searchResultShow = false
      }, 200)
      this.searchCurrentIndex = -1
    },
    handleFocus() {
      if (this.searchValue) {
        this.searchResultShow = true
        const params = {
          groupcode: this.$store.state.user.groupcode,
          proname: this.searchValue,
          page: 1,
          size: 20,
          sort: 1,
          typename: '',
          prodatetime: '',
          protype: '',
          theatercode: ''
        }
        this.getSearchResultShow(params)
        this.$store.dispatch('HandleClearSearch', false)
      }
    },
    handleLogin() {
      this.$router.push({
        name: 'Login'
      })
      this.$store.dispatch('HandleClearSearch', true)
    },
    handleNavigate(type, item) {
      if (item) {
        this.$router.push({
          name: type,
          query: {code: item.procode, appselltype: item.appselltype}
        })
        this.searchValue = ''
      } else {
        this.$router.push({
          name: type,
          query: { searchValue: this.searchValue }
        })
      }
      this.searchResultShow = false
      if (type === 'ShowDetail') {
        this.liCurrentIndex = -1
      }
      this.liCurrentIndex = 1
      this.liClickCurrentIndex = 1
      this.$store.dispatch('HandleClearSearch', false)
    },
    handleClickUseItem(item) {
      this.userShow = false
      if (item.name === 'out') {
        this.$confirm('确定退出登录？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const params = {
            groupcode: this.$store.state.user.groupcode,
            membercode: localStorage.getItem('membercode')
          }
          api_logout(params).then(res => {
            if (res.code == '0') {
              this.$store.dispatch('HandleLogout')
              localStorage.removeItem('membercode')
              localStorage.removeItem('token')
              this.$router.push({
                name: 'MyHome'
              })
            }
          })
          this.$store.dispatch('HandleClearSearch', true)
        }).catch(() => {
          
        });
      }
      else {
        this.liCurrentIndex = -1
        this.liClickCurrentIndex = -1
        this.$router.push({ name: item.pathUrl, query: {tips: item.name} })
        this.$store.dispatch('HandleClearSearch', true)
      }
    },
    handleNavigateHome() {
      this.$router.push({
        name: 'MyHome'
      })
      this.$store.dispatch('HandleClearSearch', true)
    },
    handleNavigateShow(item) {
      this.$router.push({
        name: 'AllShow',
        query: {typename: item.typename}
      })
      this.$store.dispatch('HandleClearSearch', true)
    }
  }
}
</script>

<style lang="scss" scoped>

.header-wrap {
  width: 100%;
  height: 72px;
  box-shadow: 0 2px 16px 0 rgb(220 220 220 / 50%);
}
.header-box {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  position: relative;

  .right-header {
    min-width: 55px;
    height: 100%;
    position: relative;
    line-height: 72px;

    .info-box {
      height: 100%;
      display: inline-block;
      line-height: 72px;
      cursor: pointer;
      position: relative;
      margin-left: 20px;

      .i-info-box {
        width: 16px;
        z-index: 20000;
        display: inline-block;
        margin-right: 4px;
        margin-top: 3px;
        vertical-align: middle;
        border-radius: 13px;
      }

      .user-box {
        img {
          width: 14px;
          height: 14px;
        }
      }

      .list-wrap {
        background: white;
        border: 1px solid var(--borDefault);
        // border-radius: 6px;
        top: 71px;
        position: absolute;
        width: 100%;
        z-index: 9999;
        .li-login {
          border-bottom: 1px solid var(--borDefault);
          height: 50px;
          line-height: 50px;
          width: 100%;
        }

        &>.li-login:last-child {
          border-bottom: unset;
        }
      }
    }
  }

  .search-header {
    width: 360px;
    height: 46px;
    position: relative;
    .search-header-top {
      width: 100%;
      height: 100%;
      margin-right: 16px;
      line-height: 46px;
      background: #f8f8f8;
      border-radius: 46px;
      // border: 1px solid var(--borDefault);
      display: flex;
      align-items: center;
      padding-left: 10px;

      &>img {
        height: 16px;
        width: 16px;
      }

      .line {
        height: 16px;
        width: 1px;
        background: grey;
        margin-left: 10px;
      }

      .input-search {
        width: 253px;
        height: 44px;
        font-size: 15px;
        outline: 0;
        -webkit-appearance: none;
        padding-left: 10px;
        border-top-left-radius: 46px;
        border-bottom-left-radius: 46px;
        border: 1px solid #f8f8f8;
        background-color: #f8f8f8;
        box-sizing: content-box;
      }

      .circle{
        width: 76px;
        text-align: center;
        border-radius: 0 46px 46px 0;
        height: 100%;
        // border-top: 1px solid var(--borDefault);
        box-sizing: content-box;
        background: var(--mainBtn);
        color: white;
      }
      .clearInp{
        background: transparent;
        right: 40px;
      }
      .circle img{
        vertical-align: middle;
        height: 20px;
      }
    }

    .search-header-bottom {
      text-align: left;
      background: white;
      padding: 3px 14px;
      border: 1px solid var(--borDefault);
      margin-top: 10px;
      width: 100%;
      position: absolute;
      top: 46px;
      left: 0;
      z-index: 99;

      &>div {
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
      }
    }
    
  }

  .img-box {
    height: 32px;
    // width: 150px;
    margin-top: 20px;
    
    img {
      height: 100%;
      width: auto;
    }
  }

  input {
    border: none!important;
  }

  
  .login-user{

    span{
      color: var(--fcDeep);
      cursor: pointer;
      font-size: 16px;
      padding: 0 3px;
    }
  }
}

.header-tab{
  height: 72px;
  float: left;
  position: relative;
  margin-left: 30px;
  line-height: 72px;
  white-space: nowrap;
  cursor: pointer;

  li {
    width: 117px;
    font-size: 16px;
    color: var(--fcDeep);
    display: inline-block;
    vertical-align: middle;
    height: 100%; 
    position: relative;

    &>div {
      z-index: 999;
      top: 71px;
      color: white;
      padding: 10px 0;
      background: var(--bgColMain);
      // border-radius: 8px;
    }

    .show-list {
      height: 50px;
      line-height: 50px;

      &>div {
        padding: 0 10px;
      }
      img {
        height: 24px;
        width: 24px;
        margin-right: 6px;
      }
    }

    .qrcode-box {
      position: absolute;
      top: 71px;
      width: 100%;
      // height: 170px;
      padding: 4px;
      left: 0;
      z-index: 999;
      background: var(--bgColMain);
      padding: 10px;
      padding-bottom: 15px;
      .qrCode {
        width: 100%;
        // height: 117px;
        display: block;
      }

      p {
        color: white;
        line-height: 1;
        margin-top: 10px;
      }
    }
  }

  .noselectTabLi {
    border-bottom: 2px solid transparent;
  }

  .active{
    border-bottom: 2px solid var(--btnColSec);
  }
}

.serch-active {
  &>span:first-child {
    color: var(--bgColMain);
  }
}

.user-active {
  background: var(--bgColMain);
  color: white;
}

.item-active {
  &>div {
    background: var(--bgColHover);
  }
}

.li-active {
  border-bottom: 2px solid var(--btnColSec);
  color: var(--fcMain)!important;
}
</style>
